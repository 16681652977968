<script>
	import Base from '@backend/Base.vue';

	import BOGen from '@helper/BOGen';

	export default {
		name: "AppManagementQuota",
		extends: Base,
		data() {
			return {
				Name: "AppManagementQuota",
				mrClients: {},
				mrApi: {},
				isEdit: false,
				detail_data: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			detail(v) {
				BOGen.apirest("/" + this.Name, {
					type: "detail",
					id: v.id
				}, (err, resp) => {
					if (err) return swal(err.resp.message, "", "warning")
					$("#detail").modal();
					setTimeout(() => {
						this.$set(this, "detail_data", resp.data)
					}, 50);
				}, "POST")
			},
			getClient(v) {
				var id = v.target.value
				BOGen.apirest("/" + this.Name, {
					type: "getClient",
					id: id
				}, (err, resp) => {
					if (err) return swal(err.resp.message, "", "warning")
					setTimeout(() => {
						this.$set(this, "isEdit", resp.data)
					}, 50);
					console.log(resp.data)
				}, "POST")
			}
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.api'(v) {
				if (!this.$route.params.id) this.search()
			},
			'filter.client'(v) {
				if (!this.$route.params.id) this.search()
			},
			'filter.level'(v) {
				if (!v) return
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-3">
									<h5 class="card-title">{{ObjectName}} List <span v-if="data.total">({{(data.total||0)}})</span></h5>
								</div>
								<div class="col-md-2">
									<select2 name="" v-model="filter.api" :options="mrApi" :object="['ma_id','ma_name']">
										<option value="">-- All API --</option>
									</select2>
								</div>
								<div class="col-md-2">
									<select2 name="" v-model="filter.client" :options="mrClients" :object="['mc_id','mc_name']">
										<option value="">-- All Clients --</option>
									</select2>
								</div>
								<div class="col-sm-4">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
												placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>{{fields.amq_mc_id}}</th>
									<th>{{fields.amq_ma_id}}</th>
									<th>{{fields.amq_limit_quota}}</th>
									<th>{{fields.amq_join_date}}</th>
									<th>{{fields.amq_status}}</th>
									<th>{{fields.amq_expired_date}}</th>
									<th>{{fields.amq_suspend_date}}</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mc_name}}<br><small>code : {{v.mc_code}}</small></td>
									<td>{{v.ma_name}}</td>
									<td v-if="v.amq_is_unlimited==0">
										{{v.amq_current_quota+'/'+v.amq_limit_quota}}</td>
									<td v-if="v.amq_is_unlimited==1">Unlimited</td>
									<td>{{(v.amq_join_date||'').dates("format")}}</td>
									<td>
										<StatusLabel type="status_management" :status="v.status"></StatusLabel>
									</td>
									<td v-if="v.amq_expired_date">{{(v.amq_expired_date||'').dates("format2")}}</td>
									<td v-else>-</td>
									<td v-if="v.amq_suspend_date">{{(v.amq_suspend_date||'').dates("format")}}</td>
									<td v-else>-</td>
									<td class="btn-action">
										<!-- <router-link v-if="page.moduleRole('Edit')" class="icon_action"
											:to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
												class="ti-marker-alt"></i></router-link> -->
										<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action"
											@click="changeStatusManagement(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action"
											@click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
										<a href="javascript:;" class="icon_action" @click="detail(v)" v-tooltip="'Detail'"><i
												class="ti-eye"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="amq_mc_id">
								<select2 name="amq_mc_id" v-bind="validation('amq_mc_id')" @change="getClient($event)"
									:options="mrClients" :object="['mc_id','mc_name']" v-model="row.amq_mc_id">
									<option value="">-- Select Client --</option>
								</select2>
							</BoField>
							<BoField name="amq_limit_period">
								<select2 name="amq_limit_period" v-bind="validation('amq_limit_period')"
									:options="{W:'Weekly',M:'Monthly',Y:'Yearly'}" v-model="row.amq_limit_period">
									<option value="">-- Select Period --</option>
								</select2>
							</BoField>
							<BoField name="amq_join_date" mandatory v-if="isEdit">
								<DatePicker name="amq_join_date" placeholder="DD-MM-YYYY" v-bind="{required:1}"
									v-model="row.amq_join_date"></DatePicker>
							</BoField>
							<BoField name="amq_status">
								<div class="row">
									<div class="col-md-3">
										<radio name="amq_status" v-model="row.amq_status" option="E" :attr="validation('amq_status')">
											Enabled
										</radio>
									</div>
									<div class="col-md-3">
										<radio name="amq_status" v-model="row.amq_status" option="D">Disabled</radio>
									</div>
									<div class="col-md-3">
										<radio name="amq_status" v-model="row.amq_status" option="S">Suspended</radio>
									</div>
								</div>
							</BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="amq_ma_id">
								<select2 name="amq_ma_id" v-bind="validation('amq_ma_id')" :options="mrApi"
									:object="['ma_id','ma_name']" v-model="row.amq_ma_id">
									<option value="">-- Select API --</option>
								</select2>
							</BoField>
							<div class="form-group">
								<div class="row">
									<div class="col-md-4">
										<label for="">Quota <span class="text-danger mr5">*</span></label>
										<Checkbox v-model="row.amq_is_unlimited">Unlimited</Checkbox>
									</div>
									<div v-if="!row.amq_is_unlimited" class="col-md-8">
										<label for="">QTY Quota</label>
										<input name="amq_limit_quota" v-bind="validation('amq_limit_quota')" v-model="row.amq_limit_quota"
											class="form-control" type="number" min="0" />
										<label error="amq_limit_quota" :label="fields['amq_limit_quota']"></label></div>
								</div>
							</div>
							<!-- <BoField name="amq_limit_quota" v-model="row.amq_limit_quota"
								:attr="{type:'Number',min:'0'}"></BoField> -->
							<BoField name="amq_suspend_date" v-if="row.amq_status=='S'">
								<DatePicker name="amq_suspend_date" v-bind="validation('amq_suspend_date')" placeholder="DD-MM-YYYY"
									v-model="row.amq_suspend_date"></DatePicker>
							</BoField>
						</div>
					</div>
					<hr>
					<div class="text-right">
						<router-link :to="{name:Name}" class="btn">Cancel</router-link>
						<button type="submit" class="btn btn-loading btn-success"><i class="icon-check"></i>Submit</button>
					</div>
				</div>
			</VForm>
		</div>

		<!-- Modal Deteail -->
		<div id="detail" class="modal" tabindex="-1" role="dialog" aria-labelledby="prod_cat" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Detail Quota</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-3">
								<div class="card border mb-0 h-100">
									<div class="card-body">
										<div class="form-group mb-0">
											<label class="control-label">API</label>
											<h5>{{detail_data.ma_name}}<br></h5>
											<small class="mt-1">{{detail_data.ma_token}}</small>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-9">
								<div class="card border mb-0 h-100">
									<div class="card-body">
										<div class="row">
											<div class="col-md-3">
												<div class="form-group">
													<label class="control-label">Client</label>
													<h5>{{detail_data.mc_name}}<br></h5>
													<small class="mt-1">{{detail_data.mc_code}}</small>
												</div>
											</div>
											<div class="col-md-9 border-left pl-3">
												<div class="form-group mb-0">
													<label class="control-label">PIC Client</label>
													<div class="row">
														<div class="col-md-4">
															<strong>Name</strong>
															<p>{{detail_data.mc_pic_name}}</p>
														</div>
														<div class="col-md-4">
															<strong>Position</strong>
															<p>{{detail_data.mc_pic_position}}</p>
														</div>
														<div class="col-md-4">
															<strong>Contact</strong>
															<p>{{detail_data.mc_pic_contact}}</p>
														</div>
													</div>
													<!-- <ul>
												<li>Name : {{detail_data.mc_pic_name}}</li>
												<li>Position : {{detail_data.mc_pic_position}}</li>
												<li>Contact : {{detail_data.mc_pic_contact}}</li>
											</ul> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12 mt-4">
								<div class="card">
									<h5 class="mb-3 card-title">Client Requirement</h5>
									<div class="row">
										<div class="col-md-12 mb-3">
											<div class="form-group mb-0">
												<label class="control-label">Header Default</label>
												<ul>
													<li>clientid : {{detail_data.mc_code}}</li>
													<li>apitoken : {{detail_data.ma_token}}</li>
												</ul>
											</div>
										</div>
										<hr>
										<div class="col-md-12">
											<div class="form-group mb-0">
												<label class="control-label">Service Available</label>
											</div>
										</div>
										<div class="col-md-12 mb-3">
											<div class="table-responsive">
												<table class="table">
													<thead class="bg-light">
														<th>Name</th>
														<th>Methods</th>
														<th>URL</th>
														<th>Header</th>
														<th>Parameter</th>
													</thead>
													<tbody>
														<tr v-for="(v,k) in detail_data.service" :key="k">
															<td>{{(v.ms_name||'-')}}</td>
															<td>{{(v.ms_method||'-')}}</td>
															<td>
																<span v-if="v.ms_method=='POST'">{{(detail_data.ma_url||'-')+'/p/'+v.ms_slug}}</span>
																<span
																	v-if="v.ms_method=='GET'">{{(detail_data.ma_url)+'/g/'+v.ms_slug+'?'+(v.ms_body?v.ms_body.map(e => e.field?e.field+'=[value]':'').join("&"):[])}}</span>
																<span v-if="v.ms_method=='PUT'">{{(detail_data.ma_url||'-')+'/put/'+v.ms_slug}}</span>
																<span
																	v-if="v.ms_method=='DELETE'">{{(detail_data.ma_url||'-')+'/del/'+v.ms_slug}}</span>
																<span v-if="!v.ms_method">-</span>
															<td>
																<ul v-if="v.ms_header[0].field">
																	<li v-for="(v2,k2) in v.ms_header" :key="k2">
																		{{v2.field}}
																		<span v-if="v2.value">{{' : '+v2.value}}</span>
																	</li>
																</ul>
																<span v-else>-</span>
															</td>
															<td>
																<ul v-if="v.ms_body[0].field">
																	<li v-for="(v2,k2) in v.ms_body" :key="k2">
																		{{v2.field}}</li>
																</ul>
																<span v-else>-</span>
															</td>
														</tr>
														<tr v-if="!(detail_data.service||{}).length">
															<td colspan="99">
																<h3 class="tc">Service Not Available</h3>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- /.modal-content -->
			</div>
			<!-- /.modal-dialog -->
		</div>
	</div>
</template>